import React, { useState, useEffect } from 'react'
import SabpaisaPaymentGateway from './SabpaisaPaymentGateway';
import { useParams } from 'react-router-dom';
import axios from "axios";


const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
  
const Addcasecopy = (props) => {
  const { rechargeAmt, payerName, payerEmail, payerMobile } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [payerNamee, setPayerName] = useState(payerName);
  const [payerEmaill, setPayerEmail] = useState(payerEmail);
  const [payerMobilee, setPayerMobile] = useState(payerMobile);
  const [amount, setAmount] = useState(rechargeAmt);
const [clientCode, setClientCode] = useState("ROIT54");
const [transUserName, setTransUserName] = useState("Rohitashbadbadwal0029_15678");
const [transUserPassword, setTransUserPassword] = useState("ROIT54_SP15678");
const [authkey, setAuthkey] = useState("hM09zr5fHn2OawPi");
const [authiv, setAuthiv] = useState("iiNvTFQ86i5V4tYu");
  const [clientTxnId, setclientTxnId] = useState("38722939345");
  const [callbackUrl, setCallbackUrl] = useState("https://vtludoclub.com/response");
  const [payerAddress, setPayerAddress] = useState("India");
  const [data, setData] = useState(null);
  const [udf1, setudf1] = useState(null);
  const [udf2, setudf2] = useState(null);
  const [udf3, setudf3] = useState(null);
  const [udf4, setudf4] = useState(null);
  const [udf5, setudf5] = useState(null);
  const [udf6, setudf6] = useState(null);
  const [udf7, setudf7] = useState(null);
  const [udf8, setudf8] = useState(null);
  const [udf9, setudf9] = useState(null);
  const [udf10, setudf10] = useState(null);
  const [udf11, setudf11] = useState(null);
  const [udf12, setudf12] = useState(null); // client id
  const [udf13, setudf13] = useState(null); // plan id
  const [udf14, setudf14] = useState(null); // plan name
  const [udf15, setudf15] = useState(null); // application id (product id)
  const [udf16, setudf16] = useState(null); // client subscribe plan detail id(refer to DB)
  const [udf17, setudf17] = useState(null); // payment from the COB portal
  const [udf18, setudf18] = useState(null);
  const [udf19, setudf19] = useState(null);
  const [udf20, setudf20] = useState(null);
  const [channelId, setchannelId] = useState(null);
  const [programId, setprogramId] = useState(null);
  const [mcc, setmcc] = useState(null);
  const [amountType, setamountType] = useState(null);

const[qrCodeResponse, setQrCodeResponse] = useState(null)

  console.log("amt", rechargeAmt)
  console.log("Email", payerEmail)
  console.log("recharge amount ============>" + rechargeAmt);
  // setPayerName(payerName);
  // setPayerEmail(payerEmail);
  // setPayerMobile(payerMobile);
  // setAmount(rechargeAmt);

  function generateClientReferenceId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


const fetchQrCode = async () => {
  try {
    const clientReferenceId = generateClientReferenceId(15);
    console.log("Client Reference ID: ", clientReferenceId);

    const response = await axios.post(`${baseUrl}generate-qrcode`, {
      clientReferenceId,
      amount,
      mobileNo: payerMobile,
      email: payerEmail
    });

    console.log("QR Code String: ", response.data.qrcodE_STRING);
    setQrCodeResponse(response.data.qrcodE_STRING);
  } catch (error) {
    console.error('Error:', error);
    // Handle error
  }
};

const handlePayNowClick = async (e) => {
  e.preventDefault();
  setIsOpen(true);
  const btn = document.getElementById('renderkavpayin');
  btn.click();
  
  await fetchQrCode();
  
//   window.location.href = "https://vtludoclub.com/slresponse";
};

useEffect(() => { 
  fetchQrCode();
}, []);


  const handleSubmit = (e) => {
    setIsOpen(true);
    e.preventDefault()
    const btn = document.getElementById('renderSabPaisa');
    btn.click();
  }


  const [caseId, setCaseId] = useState(null);
  const [isbn, setIsbn] = useState(null);

  useEffect(() => {
    const urlPath = window.location.pathname; 
    const pathSegments = urlPath.split('/');
    console.log(pathSegments);

    if (pathSegments.length >= 5) {
      setCaseId(pathSegments[2]); 
      setIsbn(pathSegments[5]); 
    }
  }, []);


//   const handleButtonClick = () => {
//     const amount = caseId; 
//     const mobile = isbn; 
//     const redirectUrl = `https://payment.vtludoclub.com/index.php?&amount=${amount}&mobile=${mobile}`;
//     window.location.href = redirectUrl;
//   };

const [loading, setLoading] = useState(false);

const handleButtonClick = () => {
    setLoading(true);
    const amount = caseId;
    const mobile = isbn;
    const redirectUrl = `https://payment.vtludoclub.com/index.php?&amount=${amount}&mobile=${mobile}`;
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 500); 
  };


  return (
    <div className="container-fluid" style={{ marginTop: "100px" }}>


      {/* <form className='xyz' onSubmit={handleSubmit}>
        <div

  className="add-fund-box mt-3"
  style={{ paddingTop: "0px", height: "60px" }}
>
  <div
    className="d-flex align-items-center"
    style={{
      backgroundColor: "#fafafa",
      border: "1px solid #e0e0e0",
      borderRadius: "7px",
    }}
  >
    <div
      className="d-flex align-items-center"
      style={{
        height: "60px",
        display: "flex",
        textAlign: "center",
      }}
    >
      <img
        width="40px"
        src="/UPI.png"
        alt=""
        style={{
          marginLeft: "7px",
          paddingBottom: "10px",
          paddingLeft: "3px",
          paddingTop: "5px",
        }}
      />
    </div>

    <div className="d-flex justify-content-center flex-column ml-4">
    <div id='renderSabPaisa' className='jss30'></div>
      <div className="jss30">
        <button style={{borderRadius:"8px", border:"none",backgroundColor:"none", fontWeight:"bold", fontSize:"20px"}}

          type="submit"  value="Submit" >Proceed To Pay</button>

      </div>
      <div className="jss31"></div>
    </div>
  </div>

</div>

<div
  className="d-flex align-items-center"
  style={{
    backgroundColor: "#fafafa",
    border: "1px solid #e0e0e0",
    borderRadius: "7px",
  }}
>
  <div
    className="d-flex align-items-center"
    style={{
      height: "60px",
      display: "flex",
      textAlign: "center",
    }}
  >
    <img
      width="40px"
      src="/UPI.png"
      alt=""
      style={{
        marginLeft: "7px",
        paddingBottom: "10px",
        paddingLeft: "3px",
        paddingTop: "5px",
      }}
    />
  </div>

  <div className="d-flex justify-content-center flex-column ml-4">
    <div id='renderkavpayin' className='jss30'></div>
    <div className="jss30">
      <a href={qrCodeResponse} style={{ color: "#000000" }} target="_blank" rel="noopener noreferrer">Pay Now With Phonepe Paytm</a>

    </div>
    <div className="jss31"></div>
  </div>
</div>




        <SabpaisaPaymentGateway env={"prod"} clientCode={clientCode} transUserName={transUserName} transUserPassword={transUserPassword} authkey={authkey} authiv={authiv} payerName={"VT LUDO CLUB"} payerEmail={"info@vtludoclub.com"} payerMobile={payerMobilee} clientTxnId={clientTxnId} amount={amount} payerAddress={payerAddress}  isOpen={isOpen} />
      </form> */}
      
      <div className="d-flex align-items-center" style={{ backgroundColor: "#fafafa", border: "1px solid #e0e0e0",borderRadius: "7px",}}>
                <div className="d-flex align-items-center"
                  style={{
                    height: "60px",
                    display: "flex",
                    textAlign: "center",
                  }}
                >
                <img
                  width="40px"
                  src="/UPI.png"
                  alt=""
                  style={{
                    marginLeft: "7px",
                    paddingBottom: "10px",
                    paddingLeft: "3px",
                    paddingTop: "5px",
                  }}
                />
                </div>

        <div className="d-flex justify-content-center flex-column ml-4">
              <div id='renderSabPaisa' className='jss30'></div>
                      <div className="jss30">
                         <button 
                      style={{borderRadius:"8px", border:"none",backgroundColor:"none", fontWeight:"bold", fontSize:"20px"}}
                      onClick={handleButtonClick}
                      disabled={loading} >
                      Pay Now
                    </button>
                      </div>
                      {loading && (
        <div className="loader" style={{ marginTop: '20px' }}>
          {/* You can replace this with your actual loader */}
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
                      <div className="jss31"></div>
              </div>
        </div>

      {/* <button onClick={handleButtonClick}>UPI Payment</button> */}
      


      
         <div style={{ margin:"20px 20px" }}>
     <span style={{ color: "red", fontSize:"14px"}}>
   1. यूजर पेमेंट ऐड करने के लिए QR Code का स्क्रीनशॉट लेकरशॉेंट करे
      <br/>
     फ़ोन पे पेटीएम पर जाकर पेमेंट करे
                </span>
                <br/>
                  <span style={{ color: "red", fontSize:"14px"}}>
    2. जिन यूजर को बैलेंस ऐड करने में प्रॉब्लम आ रही हैं<br/>
        वो इस नंबर पर संपर्क करके direct पेमेंट करके
      <br/>
     ऐड करवा सकते हैं 9660354877 [Only Whatsupp]
                </span>
                
                </div>
                <div style={{ margin:"20px 20px" }}>
      <span style={{ color: "red", fontSize:"14px"}}>
         
                </span></div>

    </div>
  )
}

export default Addcasecopy